<template>
  <div class="header-tab">
    <div
      class="header-tab-item"
      :class="{ 'item-active': index == curTab }"
      v-for="(item, index) in tabList"
      :key="index"
      @click="tabChanged(item, index)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "header-tab",
  computed:{
    curTab:function(){
      return this.$store.state.headerTab
    }
  },
  data() {
    return {
      tabList: [
        {
          name: this.$t("tab.home"),
          url: "/",
        },
        {
          name: this.$t("tab.case"),
          url: "caseApply",
        },
      ],
    };
  },
  methods: {
    // tab切换回调
    tabChanged(item, index) {
      if (index == this.curTab) return;

      // if (index == 1) {
      //   let isLogin = localStorage.getItem("isLogin")?Number(localStorage.getItem("isLogin")):0
      //   if(isLogin == 0){
      //     this.$emit("showLoginDia")
      //     return
      //   }
      // }

      this.$store.state.headerTab = index
      this.$router.push(item.url);
    },
  },
};
</script>
<style lang="scss" scoped>
.header-tab {
  display: flex;
  justify-content: space-evenly;
  &-item {
    // width: 64px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    cursor: pointer;
    color: #2a3451;
  }
  .item-active {
    // width: 64px;
    height: 32px;
    font-size: 14px;
    color: #1a73e9;
    // border-bottom: 2px solid #1a73e9;
  }
}
</style>
