<template>
  <div class="wx-login">
    <div class="wx-login-title">微信扫码关注公众号进行登录</div>
    <div class="wx-login-qrcode">
      <img v-if="qrCodeUrl" width="165" height="165" :src="qrCodeUrl" />
    </div>
    <div class="wx-login-agreement">
      扫码登录表示同意<span @click="gotoService">服务协议</span>和<span @click="gotoSecret">隐私协议</span>
    </div>
  </div>
</template>

<script>
import { getQrCode, getLoginResult } from "@/api/login";
import { getUserDetail } from "@/api/login";

let _loginInterval, _sceneId;
export default {
  name: "wx-login",
  data() {
    return {
      qrCodeUrl: null,
    };
  },
  mounted() {
    this.getQrCode();
  },
  beforeDestroy() {
    window.clearInterval(_loginInterval);
  },
  methods: {
    getQrCode() {
      getQrCode().then((res) => {
        this.qrCodeUrl = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${res.data.data.ticket}`;
        _sceneId = res.data.data.sceneStr;
        this.setLoginInterval();
      });
    },
    setLoginInterval() {
      _loginInterval = window.setInterval(() => {
        this.getLoginResult();
      }, 1500);
    },
    getLoginResult() {
      getLoginResult(_sceneId)
        .then((res) => {
          console.log(res);
          if (res.data.code == "0") {
            // 清除定时器
            window.clearInterval(_loginInterval);
            //存储token
            localStorage.setItem("accessToken", res.data.token);
            //获取用户详细信息
            let userId = res.data.data.id;
            // 登录成功提示
            this.$message.success("登录成功");
            // 查询用户信息
            return getUserDetail(userId);
          }
        })
        .then((res) => {
          //保存用户信息
          if (res) {
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
            this.$emit("wxLoginSuccess");
            this.$router.push("relationChart");
          }
        });
    },
    gotoService(){
      window.open(`${window.location.href}protocol`)
    },
    gotoSecret(){
      window.open(`${window.location.href}secret`)
    }
  },
};
</script>

<style lang="scss" scoped>
.wx-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  .wx-login-title {
    height: 16px;
    font-size: 16px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #000000;
    line-height: 12px;
  }
  .wx-login-qrcode {
    width: 165px;
    height: 165px;
    margin-top: 30px;
  }
  .wx-login-agreement {
    height: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
    margin-top: 30px;
    span {
      color: #2e43eb;
      cursor: pointer;
    }
  }
}
</style>
