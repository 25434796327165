import request from "@/api/http";

/**
 * 获取加密key
 */
export const getKey = () => {
  return request({
    url: "/api/system/key/get",
    method: "get",
  });
};

/**
 * 用户登录
 *
 * @params {loginInfo} 用户名
 * @params {loginPwd} 登录密码，使用tokenKey加密
 * @params {userKey} 密码
 */
export const login = (loginInfo, loginPwd, userKey) => {
  return request({
    url: "/api/system/user/login",
    method: "post",
    data: {
      loginInfo,
      loginPwd,
      userKey,
    },
  });
};

/**
 * 获取用户详情信息
 *
 * @params {userId} 用户Id，登录后获取
 */
export const getUserDetail = (userId) => {
  return request({
    url: "/api/system/user/detail",
    method: "post",
    params: {
      userId,
    },
  });
};

/**
 * 注册前，检验用户是否存在
 *
 * @params {loginInfo} 邮箱
 */
 export const userCheck = (loginInfo,) => {
  return request({
    url: "/api/system/user/check",
    method: "post",
    params: {
      loginInfo
    },
  });
};

/**
 * 注册第一步
 *
 * @params {loginInfo} 注册邮箱
 * @params {registerPwd} 登录密码，使用tokenKey加密
 * @params {userKey} 密码
 */
 export const registerFirst = (loginInfo, registerPwd, userKey) => {
  return request({
    url: "/api/system/register/first",
    method: "post",
    data: {
      loginInfo,
      registerPwd,
      userKey,
    },
  });
};

/**
 * 注册最终步
 *
 * @params {loginInfo} 注册邮箱
 * @params {code} 验证码
 */
 export const register = (loginInfo, code) => {
  return request({
    url: "/api/system/register/check",
    method: "post",
    data: {
      loginInfo,
      code,
    },
  });
};

/**
 * 获取微信二维码
 */
export const getQrCode = () => {
  return request({
    url: "/api/system/wechat/getQrCode",
    method: "get",
  });
};

/**
 * 轮询扫码结果
 * 
 * @params {sceneId} 获取二维码时返回的场景id
 */
 export const getLoginResult = (sceneId) => {
  return request({
    url: "/api/system/wechat/getLoginResult",
    method: "get",
    params:{
      sceneId
    }
  });
};