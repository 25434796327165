import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: '',
    redirect: '/'
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/information/index.vue"),
  },
  {
    path: "/monitor",
    name: "monitor",
    component: () => import("../views/monitor/index.vue"),
  },
  {
    path: "/chartList",
    name: "chartList",
    component: () => import("../views/relation/chartlist.vue"),
  },
  {
    path: "/relationChart",
    name: "relationChart",
    component: () => import("../views/relation/relationchart.vue"),
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: () => import("../views/aboutus/index.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/information/index.vue"),
  },
  {
    path: "/property",
    name: "property",
    component: () => import("../views/property/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/login/register.vue"),
  },
  {
    path: "/protocol",
    name: "protocol",
    component: () => import("../views/protocol/service.vue"),
  },
  {
    path: "/secret",
    name: "secret",
    component: () => import("../views/protocol/secret.vue"),
  },
  {
    path: "/caseApply",
    name: "caseApply",
    component: () => import("../views/caseApply/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to,from,next) =>{
  
  if(to.name == ""){
    store.commit("updateHeaderTab",0)
  }
  else if(to.name == "relationChart"){
    store.commit("updateHeaderTab",1)
  }
  
  next()
})

export default router;
