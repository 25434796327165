import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: false,
    headerTab: 0

  },
  mutations: {
    updateLogStatus(state, isLogin) {
      state.isLogin = isLogin
    },
    updateHeaderTab(state, headerTab) {
      state.headerTab = headerTab
    }
  },
  actions: {},
  modules: {},
});
