import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";
import "./assets/theme/index.css";

import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import ElementLocale from "element-ui/lib/locale";


Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(ElementUI);


import VueClipboards from 'vue-clipboards';

Vue.use(VueClipboards);

const messages = {
  en: {
    message: "hello",
    login:"login",
    tab: {
      relation: "Go Track",
      case: "Case",
      home: "Home",
    },
    home: {
      section1: {
        title: "Bro，your security expert",
        subTitle: "Solving blockchain Security Issues with AI and Data",
        describe: "Digital asset traceability｜security audit service｜on-chain tracking",
        contactUs: "Contact Us",
        caseApply:"Case"
      },
      section2: {
        title: "Digital Asset Traceability ",
        subTitle: "Restore the truth of the incident, find the suspects, and recover the stolen digital assets",
        info1: {
          num: "90%",
          name: "case detection",
          type: "Crimes within the team"
        },
        info2: {
          num: "65%",
          name: "case detection",
          type: "Domestic hackers commit crimes"
        },
        info3: {
          num: "$100",
          name: "Digital assets",
          type: "have been recovered"
        },
        decribe1: "No. 1 in the industry, with a market share of over 60% in major cases",
        decribe2: "Detected multiple transnational cases",
        decribe3: "Assisted the police in arresting 30+ suspects"
      },
      section3: {
        title: "Security Audit Service",
        subTitle: "Conduct a comprehensive security assessment of blockchain code to identify vulnerabilities and recommend fixes",
        decribe1: "vulnerabilities and recommend fixes.",
        decribe2: "DeFi Security Audit Service",
        decribe3: "NFT and Metaverse Security Audit Service",
        tip1: "Client Type",
        tip2: "Exchange",
        tip3: "Wallet company"
      },
      section4: {
        title: "GoTrack: On-chain tracking service",
        subTitle: "Millions of address labels, graph visualization, real-time tracking of digital assets",
        decribe1: "Real-time monitoring of blockchain transactions",
        decribe2: "Millions of Address Labels",
        decribe3: "Digital asset on-chain Graph",
        decribe4: "Assist the police in freezing digital assets",
        tip: "Support all on-chain tokens based on ETH, TRX, BSC, etc.",
        goTrack: "Go To Track",
      },
      section5: {
        title: "SUCCESS STORIES ",
        decribe1: "<strong  style='font-size:14px;line-height:24px;'>Web3 Security Services Clients：</strong><br /><span style='font-size:14px;line-height:24px;'>Exchange：<br />Coinex、Coinbean、Bitmax、 hotbtx、btcbox<br />Chain：Tron、wicc <br />Wallet: Coldlar <br /> DeFi: BXH、Accessifi、starfish <br />Metaverse：Starrynift</span>",
        decribe2: "<span style='font-size:14px;line-height:24px;'>In 2022, assisted the local police to destroy a multinational black production team, and helped the client recover millions of RMB losses. This case involved APT attacks, targeting large Internet companies, and using virtual assets to launder money</span>",
        decribe3: "<span style='font-size:14px;line-height:22px;'>Assisted a local police in investigating the world's second largest case (BXH theft case) in 2021. It is worth mentioning that the victims of this case have invited more than 10 security companies to participate, and Bit Jungle only took three days to provide high-value clues to characterize the case and obtained millions of RMB in rewards from the victims, which shows that Bit jungle is leading in the field of digital asset traceability</span>",
        decribe4: "<span style='font-size:14px;line-height:24px;'>In 2019, Bit Jungle assisted the police in a certain place to crack the USDT fake recharge case and recovered 10 million RMB worth of digital assets for the victims<br /><br />In 2021, assisted the local police in cracking the case of stealing digital assets using smart contracts, and helped users recover the stolen digital assets.</span>",
      }
    },
    case: {
      header: {
        title: "Case assistance",
        warnMsg: "If your digital assets is stolen, Bitjungle will provide free case assessment assistance. After you submit the relevant information, we will promptly analyze your case and contact you through the provided contact details with any updates."
      },
      notify: {
        title: "Submit successfully",
        content: "If the case is accepted, we will contact you within 3 working days."
      },
      form:{
        requiredMsg: "* indicates a required field.",
        contact: {
          label:"Contact information",
          placeholder: "Email/Telegram/WeChat/Phone or others",
          requiredWarnMsg: "Please enter your contact information"
        },
        storage: {
          label: "How do you store your digital assets",
          placeholder: "Please enter the platform you use.",
          requiredWarnMsg: "Please select or fill in the storage type",
          data:[
            'Browser extension wallet',
            'HD wallet',
            'Hardware wallet',
            'Exchange',
            'Others'
          ],
          other: 'Others'
        },
        incidents: {
          label: "Have you encountered",
          placeholder: "Please enter your case types",
          requiredWarnMsg: "Please select or fill in the case type",
          data:[
            'Scam',
            'Phishing airdrop',
            'Funds stolen (wallet hacked)',
            'Ransomware attacks',
            'Authorized theft',
            'Fake wallets or malware',
            'Pig slaughter scam',
            'Others'
          ],
          other: 'Others'
        },
        loss_usd: {
          label: "Amount of loss (USD)",
          placeholder: "Please enter the amount of loss",
          requiredWarnMsg: "Please enter the amount of loss"
        },
        loss_addr: {
          label: "Stolen address/transaction hash",
          placeholder: "Please enter the stolen address/transaction hash",
          requiredWarnMsg: "Please enter the stolen address/transaction hash"
        },
        remark: {
          label: "Additional Notes",
          placeholder: "Please enter your additional Notes"
        },
        commitBtn: 'Submit'
      },
    },
    ...enLocale,
  },
  zh: {
    message: "你好",
    login:"登录",
    tab: {
      relation: "链上追踪",
      case: "案件援助",
      home: "首页",
    },
    home: {
      section1: {
        title: "你身边的区块链安全专家",
        subTitle: "通过AI和数据解决区块链安全问题",
        describe: "数字资产溯源｜安全审计服务｜链上追踪",
        contactUs: "联系我们",
        caseApply:"案件提交"
      },
      section2: {
        title: "数字资产溯源",
        subTitle: "还原事件真相、找到嫌疑人、挽回被盗数字资产",
        info1: {
          num: "90%",
          name: "破案率",
          type: "团队内部作案"
        },
        info2: {
          num: "65%",
          name: "破案率",
          type: "团队黑客作案"
        },
        info3: {
          num: "$100",
          name: "金额",
          type: "已挽回数字资产"
        },
        decribe1: "行业第一，重大案件市场占有率60%以上",
        decribe2: "侦破多起跨国案件",
        decribe3: "协助警方抓获30+嫌疑人"
      },
      section3: {
        title: "安全审计服务",
        subTitle: "针对区块链代码进行全面的安全评估以识别漏洞和推荐修复方法",
        decribe1: "针对数字钱包和交易所进行风险评估",
        decribe2: "DeFi安全审计服务",
        decribe3: "NFT和元宇宙安全审计服务",
        tip1: "客户类型",
        tip2: "交易所",
        tip3: "钱包公司"
      },
      section4: {
        title: "GoTrack : 链上追踪服务",
        subTitle: "数百万地址标签、图谱可视化、实时追踪数字资产",
        decribe1: "实时监控区块链交易",
        decribe2: "数百万地址标签",
        decribe3: "资产链路图谱",
        decribe4: "协助警方冻结数字资产",
        tip: "支持所有基于 ETH 、 TRX 、BSC等链上代币",
        goTrack: "Go To Track",
      },
      section5: {
        title: "成功案例",
        decribe1: "<strong>Web3安全服务客户：</strong><br /><span style='line-height:29px;'>交易所：<br />Coinex、Coinbean、Bitmax、hotbtx、btcbox<br />链：Tron、wicc<br />钱包：Coldlar<br />DeFi: BXH、Accessifi、starfish<br />元宇宙：Starrynift</span>",
        decribe2: "<span style='line-height:29px;'>2022年协助某地警方打掉一跨国黑产团队，帮助客户挽回数百万人民币损失。此案件涉及APT攻击、针对大型互联网企业、使用虚拟资产洗钱</span>",
        decribe3: "<span style='line-height:29px;'>2021年协助某地警方侦办全球第二大案件(BXH 被盗案)，值得一提的是此案件受害者曾邀请10余家安全公司参与，比特丛林仅用三天提供高价值线索对案件进行定性并获取到受害者百万人民币奖励，可见比特丛林在数字资产溯源领域领先程度</span>",
        decribe4: "<span style='line-height:29px;'>2019年比特丛林协助某地警方破获USDT假充值案件，为受害者挽回价值1000万人民币的<br /><br />2021年协助某地警方破获利用智能合约盗取数字资产案件，并帮用户挽回被盗的数字资产</span>",
      }

    },
    case: {
      header: {
        title: "案件提交",
        warnMsg: "如果您的加密货币被盗，Bitjungle 将提供免费的案件评估援助服务。在您提交相关信息之后我们会尽快分析您的案件，并在有最新进展时，通过您提供的联系方式与您联系。"
      },
      notify: {
        title: "提交成功",
        content: "如案件被受理，我们将在3个工作日内联系您"
      },
      form:{
        requiredMsg: "* 表示必填",
        contact: {
          label:"联系方式",
          placeholder: "邮件/ Telegram/ 微信/ 电话或其他联系方式",
          requiredWarnMsg: "请输入联系方式"
        },
        storage: {
          label: "您以何种方式存储数字资产",
          placeholder: "请输入其他平台类型",
          requiredWarnMsg: "请选择或填写平台类型",
          data:[
            'MetaMask 钱包',
            'HD钱包',
            '硬件钱包',
            '交易所',
            '其他'
          ],
          other: '其他'
        },
        incidents: {
          label: "您是否遭遇",
          placeholder: "请输入其他案件类型",
          requiredWarnMsg: "请选择或填写案件类型",
          data:[
            '诈骗',
            '钓鱼空头',
            '资金被盗（钱包被黑）',
            '勒索攻击',
            '授权盗币',
            '假冒钱包或恶意软件',
            '杀猪盘',
            '其他'
          ],
          other: '其他'
        },
        loss_usd: {
          label: "损失金额（美元）",
          placeholder: "请输入损失金额",
          requiredWarnMsg: "请输入损失金额"
        },
        loss_addr: {
          label: "被盗地址/交易哈希",
          placeholder: "请输入被盗的地址或交易哈希",
          requiredWarnMsg: "请输入被盗地址或交易哈希"
        },
        remark: {
          label: "补充说明",
          placeholder: "请输入其他情况说明"
        },
        commitBtn: '提交'
      },
    },
    ...zhLocale,
  },
};
const i18n = new VueI18n({
  locale: localStorage.getItem("lang")||"zh", // set locale
  messages, // set locale messages
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

router.beforeEach((to, from, next)=>{
  // const accessToken = localStorage.getItem("accessToken");
  // if(!accessToken && to.path!="/"){
  //   next({ path: '/' })
  // }else{
    next()
  // }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
